import React from 'react';
import Acnavbar from '../components/Acnavbar';
import { Container } from 'react-bootstrap';
import Seo from '../shared/seo';
import Footer from '../components/footer';
import logo from '../assets/images/softobotics-logo-tab.svg';
import Wrapper from '../components/Wrapper';

function Termsofuse() {
  return (
    <Wrapper>
      <Seo
        title='Terms of Use: Guidelines for Engaging with Softobotics'
        description="Read our terms of use to understand the guidelines and agreements for using Softobotics' services and engaging with our platform."
      />

      <Acnavbar home={false} pathname={'/terms-of-use'} logo={logo} logoLogic={false} />
      <Container>
        <div className='mt-8 mb-4 mb-lg-5'>
          <div className='py-4 fnt-20 fnt-lg-24 fw-800 lh-lg-40'>
            <h1>
              {' '}
              Welcome to{' '}
              <span className='softobotics-term'>
                <a href='https://www.softobotics.com'>Softobotics</a>
              </span>
              !
            </h1>
          </div>
          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>Acceptance of Terms</h2>
          <p className='fnt-14 fnt-lg-16 '>
            These Terms of Use outline the rules and regulations for the use of Softobotics’s
            Website, located at <a href='https://www.softobotics.com'>www.softobotics.com</a>{' '}
            (“Softobotics.com” or “site”). Softobotics reserves the right to update its Terms of Use
            at any time without notice to you. Your continued use of the site after any such
            modifications constitutes your acceptance of the modified terms and conditions. By
            accessing this website we assume you accept these Terms of Use. Do not continue to use
            the site if you do not agree to take all of the Terms of Use stated on this page.
          </p>

          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>Agreement</h2>
          <p className='fnt-14 fnt-lg-16  mb-4'>
            These legal notices and Terms of Use are a contract between you and Softobotics (the
            “Agreement”) and govern your visit to and use of the site
          </p>

          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>Comments and Posting</h2>
          <div className='fnt-14 fnt-lg-16'>
            Parts of the site offers an opportunity for users to post and exchange opinions and
            information in certain areas of the site. Softobotics does not filter, edit, publish or
            review Comments or Posts prior to their presence on the site. Such Comments do not
            reflect the views and opinions of Softobotics, its agents and/or affiliates. Comments
            reflect the views and opinions of the person who post their views and opinions. To the
            extent permitted by applicable laws, Softobotics shall not be liable for the Comments or
            for any liability, damages or expenses caused and/or suffered as a result of any use of
            and/or posting of and/or appearance of the Comments on this website.
            <div className='my-4'>
              Softobotics reserves the right to monitor all Comments and to remove any Comments
              which can be considered inappropriate, offensive or causes breach of these Terms of
              Use.
            </div>
            <div className='mb-4'>You warrant and represent that</div>
            <div className='fnt-lg-16 mb-4 mb-lg-0'>
              You are entitled to post the Comments on our website and have all necessary licenses
              and consents to do so
            </div>
            <div className='fnt-lg-16 mb-4 mb-lg-0'>
              The Comments do not invade any intellectual property right, including without
              limitation copyright, patent or trademark of any third party
            </div>
            <div className='fnt-lg-16 mb-4 mb-lg-0'>
              {' '}
              The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise
              unlawful material which is an invasion of privacy
            </div>
            <div className='fnt-lg-16 my-4 mb-lg-0'>
              The Comments will not be used to solicit or promote business or custom or present
              commercial activities or unlawful activity.
            </div>
            <div className='fnt-lg-16'>
              You hereby grant Softobotics a non-exclusive license to use, reproduce, edit and
              authorize others to use, reproduce and edit any of your Comments in any and all forms,
              formats or media.
            </div>
          </div>
          <h2 className='fw-600 my-4 fnt-18 fnt-lg-20'>Content Liability</h2>
          <p className='fnt-14 fnt-lg-16 mb-4'>
            We shall not be hold responsible for any content that appears on your website. You agree
            to protect and defend us against all claims that is rising on your website. No link(s)
            should appear on any website that may be interpreted as libelous, obscene or criminal,
            or which infringes, otherwise violates, or advocates the infringement or other violation
            of, any third party rights.
          </p>
          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>Cookies</h2>
          <p className='fnt-14 fnt-lg-16  mb-4'>
            We employ the use of cookies. By accessing the site, you agreed to use cookies in
            agreement with the Softobotics’s Privacy Policy. Most interactive websites use cookies
            to let us retrieve the user’s details for each visit. Cookies are used by our site to
            enable the functionality of certain areas to make it easier for people visiting our
            site. Some of our affiliate / advertising partners may also use cookies.
          </p>
          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>Disclaimer of Warranties</h2>
          <p className='fnt-14 fnt-lg-16  mb-4'>
            <a href='https://www.softobotics.com'>www.softobotics.com</a>, including all its
            content, is provided to you on an “as is” and “as available” basis with no warranties of
            any kind. By using the site, you expressly agree that such use is at your sole risk.
            Neither Softobotics, nor any of its respective officers, directors, partners, members,
            employees, agents, third-party content providers, designers, contractors, distributors,
            merchants, sponsors, licensors or the like (collectively “representatives”) warrant that
            the use of the site will be uninterrupted or error-free. Neither Softobotics nor its
            representatives make any representations or warranties of any kind, either express or
            implied, and Softobotics and its representatives hereby disclaim any and all such
            warranties, including, but not limited to, the implied warranty of merchantability,
            fitness for a particular purpose, title and non-infringement to the fullest extent
            permitted by law. To the fullest extent permitted by law, Softobotics also disclaims any
            warranties for the security, reliability, timeliness, and performance of this site.
          </p>
          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>Hyperlinking to our Content</h2>
          <div className='fnt-14 fnt-lg-16  mb-4'>
            <div className='mb-4'>
              The following organizations may link to our site without prior written approval:
            </div>

            <div className='fnt-14 fnt-lg-16'>Government agencies;</div>
            <div className='fnt-14 fnt-lg-16'>Search engines;</div>
            <div className='fnt-14 fnt-lg-16'>Search engines;</div>
            <div className='fnt-14 fnt-lg-16'>News organizations;</div>
            <div className='fnt-14 fnt-lg-16 mb-4'>
              {' '}
              Online directory distributors may link to our site in the same manner as they
              hyperlink to the websites of other listed businesses; and System wide Accredited
              Businesses except soliciting non-profit organizations, charity shopping malls, and
              charity fundraising groups which may not hyperlink to our Web site.
            </div>

            <div className='fnt-14 fnt-lg-16  mb-4'>
              These organizations may link to our home page, to publications or to other website
              information so long as the link: (a) is not in any way deceptive; (b) does not falsely
              imply sponsorship, endorsement or approval of the linking party and its products
              and/or services; and (c) fits within the context of the linking party’s site.
            </div>

            <div className='fnt-14 fnt-lg-16  mb-4'>
              {' '}
              If requested and required, we will approve link requests from above listed
              organizations if we decide that: (a) the link would not make us look unfavorably to
              ourselves or to our accredited businesses; (b) the organization does not have any
              negative records with us; (c) the benefit to us from the visibility of the hyperlink
              compensates the absence of Softobotics; and (d) the link is in the context of general
              resource information.
            </div>

            <div className='fnt-14 fnt-lg-16  mb-4'>
              {' '}
              If you are one of the organizations listed above and are interested in linking to our
              site, you must inform us by sending an e-mail to Softobotics’s Legal Department.
              Please include your name, your organization name, contact information as well as the
              URL of your website, a list of any URLs from which you intend to link to our site, and
              a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a
              response.
            </div>
          </div>
          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>iFrames</h2>
          <p className='fnt-14 fnt-lg-16  mb-3'>
            Without prior approval and written permission, you may not create frames around our
            webpages that alter in any way the visual presentation or appearance of our site.
          </p>
          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>Links to Third Party Sites</h2>
          <p className='fnt-14 fnt-lg-16  mb-4'>
            The links on <a href='https://www.softobotics.com'>www.softobotics.com</a> will let you
            leave Softobotics’s site. The linked sites are not under the control of Softobotics and
            Softobotics is not responsible for the contents of any linked site or any link contained
            in a linked site, or any changes or updates to such sites. Softobotics is not
            responsible for webcasting or any other form of transmission received from any linked
            site. Softobotics is providing these links to you only as a convenience, and the
            inclusion of any link does not imply endorsement by Softobotics of any such third party
            website(s).
          </p>
          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>
            License and Intellectual Property Ownership
          </h2>
          <div className='fnt-14 fnt-lg-16  mb-4'>
            Unless otherwise stated, Softobotics and/or its licensors own the intellectual property
            rights for all material on <a href='https://www.softobotics.com'>www.softobotics.com</a>
            . All intellectual property rights are reserved. You may access this from{' '}
            <a href='https://www.softobotics.com'>www.softobotics.com</a>for your own personal use
            subjected to restrictions set in these Terms of Use.
            <div className='my-3'>You must not:</div>
            <div className='fnt-14 fnt-lg-16'>
              Republish, Reproduce, duplicate or copy material from the site.
            </div>
            <div className='fnt-14 fnt-lg-16'>Redistribute content from the site; or</div>
            <div className='fnt-14 fnt-lg-16'>
              Sell, rent or sub-license material from the site, without obtaining explicit and
              prior-written consent from Softobotics.
            </div>
          </div>
          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>Indemnity</h2>
          <p className='fnt-14 fnt-lg-16  mb-4'>
            As a condition to your use of{' '}
            <a href='https://www.softobotics.com'>www.softobotics.com</a>, you agree to indemnify,
            defend and hold harmless Softobotics and its Representatives against any and all claims,
            liabilities, damages, costs or other expenses (including, without limitation, attorneys’
            fees) that may arise directly or indirectly out of or from your breach of this Agreement
            and/or your use of the site or resulting from any action (or inaction) you take or
            decision you make in reliance on the information contained herein.
          </p>
          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>Limitation of Liability</h2>
          <div className='fnt-14 fnt-lg-16  mb-4'>
            <div className='mb-3'>
              {' '}
              By using the site, you acknowledge: (i) that your use of the site is at your sole
              risk; (ii) that you assume full responsibility for all costs associated with your use
              of the site; and (iii) that in no event shall Softobotics or any party involved in
              creating, producing or delivering the site be liable for any damages, losses or
              liabilities of any kind related to (a) your reliance on or use or inability to use the
              information, materials, products or services on the site, (b) any errors or omissions
              in the content of the site, (c) your access to, use of or inability to use the site or
              (d) any failure of performance, error, omission, interruption, defect or delay in
              operation or transmission, computer virus, line or system failure or other computer
              malfunction, in each case ((a) through (d)) including without limitation direct,
              indirect, incidental, special, punitive, exemplary, compensatory or consequential
              damages, lost profits and/or loss of or damage to property whether the alleged
              liability is based on contract, tort, negligence strict liability or any other basis,
              even if Softobotics or such party has been advised of the possibility of such damages,
              losses or liabilities. Softobotics reserves the right to alter or remove the content
              of this site or suspend or terminate your use in any way, at any time, for any reason,
              without prior notification, and will not be liable in any way for possible
              consequences of such changes.
            </div>

            <div>
              The limitations and prohibitions of liability set forth above and elsewhere on this
              site: (a) are subject to the preceding paragraph; and (b) govern all liabilities
              arising under the disclaimer, including liabilities arising in contract, in tort and
              for breach of statutory duty. As long as the site and the information and services on
              the site are provided free of charge, we will not be liable for any loss or damage of
              any nature.
            </div>
          </div>
          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>
            Removal of links from <a href='https://www.softobotics.com'>www.softobotics.com</a>
          </h2>
          <p className='fnt-14 fnt-lg-16  mb-4'>
            If you find any link on our site that is offensive for any reason, you are free to
            contact and inform us any moment. We will consider requests to remove such links but we
            are not obligated to or so or to respond to you directly.
          </p>
          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>Reservation of Rights</h2>
          <p className='fnt-14 fnt-lg-16  mb-4'>
            We reserve the right to request that you remove all links or any particular link to our
            site. You approve to immediately remove all links to our site upon request. We also
            reserve the right to amend these Terms of Use and it’s linking policy at any time. By
            continuously linking to our site, you agree to be bound to and follow these linking
            Terms of Use.
          </p>
          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>Use of Terminology</h2>
          <p className='fnt-14 fnt-lg-16  mb-4'>
            The following terminology applies to these Terms of Use, Privacy Statement and
            Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you, the
            person logging on this website and compliant to Softobotics’s Terms of Use. “Company”,
            “Ourselves”, “We”, “Our” and “Us”, refers to Softobotics. “Party”, “Parties”, or “Us”,
            refers to both the Client and Softobotics. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our assistance to the
            Client in the most appropriate manner for the express purpose of meeting the Client’s
            needs in respect of provision of Softobotics’s stated services, in accordance with and
            subject to, prevailing laws of United States of America. Any use of the above
            terminology or other words in the singular, plural, capitalization and/or he/she or
            they, are taken as interchangeable and therefore as referring to same.
          </p>
          <h2 className='fw-600 mb-3 fnt-18 fnt-lg-20'>Your Privacy</h2>
          <p className='fnt-14 fnt-lg-16  mb-4'>Please read our Privacy Policy</p>
          <h2 className='fw-600 mb-4 fnt-18 fnt-lg-20'>Miscellaneous</h2>
          <div className='fnt-14 fnt-lg-16  mb-4'>
            Softobotics’s failure to exercise or enforce any right or provision of this Agreement at
            an instance will not deem a waiver of such right or provision. If any provision of this
            Agreement is held by a court of competent jurisdiction to be invalid or unenforceable,
            then such provision shall be enforced to the maximum extent permissible so as to affect
            the intent of this Agreement, and the remainder of this Agreement shall continue in full
            force and effect. Except for trademark and copyright matters governed by the federal
            laws of the United States, the foregoing provisions shall be governed by and construed
            in accordance with the substantive laws of the State of New Jersey, without regard to
            the principles of conflict of law thereof.
            <div className='my-3'>Let’s create something amazing together!</div>
            <a href='mailto:contact@softobotics.com'>Let’s Connect</a>
          </div>
        </div>
      </Container>
      <Footer />
    </Wrapper>
  );
}

export default Termsofuse;
